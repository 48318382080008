import React from "react";
import PolicyCard from "./PolicyCard";
import {
	Box,
	Heading,
	Container,
	Text,
	VStack,
	UnorderedList,
	List,
	ListIcon,
	ListItem,
	Grid,
	Divider,
	AspectRatio,
} from "@chakra-ui/react";

const HomeSectionTwo = () => {
	return (
		<Box as="section" bg="blue.600">
			<Box mx="auto" w="87.5vw" maxW="80rem" py="4rem">
				<Grid gridTemplateColumns={["1fr", "1fr", "1fr 1fr 1fr"]} gap="2rem">
					<PolicyCard title="Local Business">
						I will encourage new businesses to open in Kingborough. With council
						I want to explore ways to showcase new and existing businesses
						through improved dialogue with the community and business enterprise
						centre, via events, meetings, newsletters and social media. As a
						local small business owner, I’m highly conscious of the needs of our
						local businesses.
					</PolicyCard>
					<PolicyCard title="Commercial Composting Facility">
						Our community will greatly benefit from our very own commercial
						composting facility here in Kingborough. I will initiate an
						investigation of its viability. Such an operation can be a
						profitable venture, environmentally sound, and is worthy of
						consideration for our own municipality.
					</PolicyCard>
					<Box bg="white" p="2rem" borderRadius="xl">
						<VStack textAlign="center" spacing="1rem">
							<Heading as="h3" size="md">
								Rates capped at CPI
							</Heading>
							<Box w="4rem" h="0.25rem" bg="yellow.400" />
							<Text>
								It is apparent to me that the yearly council budget is not
								enough to meet our needs and desires. I do NOT believe it is
								fair to raise more funds by raising rates. I understand the
								financial pressures placed on us as the cost of living
								increases. I stand by capping rates at the Consumer Price Index.
							</Text>

							<Text color="red.400">However......</Text>
							<Text>
								I want to see our council become{" "}
								<Text as="span" textDecoration="underline">
									more profitable
								</Text>{" "}
								so we can fund more projects and services. I will work hard at:
							</Text>
							<UnorderedList spacing="0.5rem" textAlign="left" w="90%">
								<ListItem>
									Investigating the feasibility of Council investment in
									ventures that return a profit
								</ListItem>
								<ListItem>
									Advocating for a statewide approach to increased developer
									contributions
								</ListItem>
								<ListItem>Exploring crowdfunding opportunities</ListItem>
								<ListItem>Elevating our grant potential</ListItem>
							</UnorderedList>
						</VStack>
					</Box>
				</Grid>
			</Box>
		</Box>
	);
};

export default HomeSectionTwo;
