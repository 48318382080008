import React from "react";
import {
	Box,
	Heading,
	Container,
	Text,
	VStack,
	Flex,
	UnorderedList,
	List,
	ListIcon,
	ListItem,
	Grid,
	Divider,
	AspectRatio,
} from "@chakra-ui/react";
import * as Icon from "react-icons/bi";
import * as Feather from "react-icons/fi";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const getData = graphql`
	{
		file(name: { eq: "clare-headshot" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(
					width: 400
					placeholder: BLURRED
					formats: [AVIF, AUTO, WEBP]
				)
			}
		}
		logo: file(name: { eq: "Kingborough-Thrives-stack" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(
					width: 220
					placeholder: BLURRED
					formats: [AVIF, AUTO, WEBP]
				)
			}
		}
	}
`;

const HomeSectionOne = () => {
	const data = useStaticQuery(getData);
	const clare = getImage(data.file);
	const logo = getImage(data.logo);
	return (
		<>
			<Box bg="gray.50" pt="2rem" overflow="hidden">
				<Flex mx="auto" w="87.5vw" maxW="80rem" justify="center">
					<Box maxH="100%" mr="2rem" position="relative" top="1rem">
						<GatsbyImage image={clare} alt="Clare Glade-Wright" />
					</Box>
					<Box as={Link} to="/" alignSelf="center" maxH="100%" mr="2rem">
						<GatsbyImage image={logo} alt="Kingborough Thrives logo" />
					</Box>
					<VStack
						pl="2rem"
						align="flex-start"
						as="nav"
						alignSelf="center"
						spacing="1rem"
						display={["none", "none", "flex"]}
					>
						<Heading
							size="md"
							bg="blue.700"
							color="white"
							p="1rem"
							borderRadius="lg"
							as={Link}
							to="/"
							_hover={{ bg: "blue.800" }}
						>
							Home
						</Heading>
						<Heading
							size="md"
							bg="blue.600"
							color="white"
							p="1rem"
							borderRadius="lg"
							as={Link}
							to="/about/"
							_hover={{ bg: "blue.800" }}
						>
							About Clare
						</Heading>
						<Heading
							size="md"
							bg="blue.600"
							color="white"
							p="1rem"
							borderRadius="lg"
							as={Link}
							to="/kingborough-thrives/"
							_hover={{ bg: "blue.800" }}
						>
							Kingborough Thrives
						</Heading>
						<Heading
							size="md"
							bg="blue.600"
							color="white"
							p="1rem"
							borderRadius="lg"
							as={Link}
							to="/contact/"
							_hover={{ bg: "blue.800" }}
						>
							Get in Touch
						</Heading>
					</VStack>
				</Flex>
			</Box>
			<Box w="100%" bg="yellow.400" h="2rem" />
			<Box bg="blue.600" color="white">
				<Box mx="auto" w="87.5vw" maxW="80rem" py="4rem">
					<Heading as="h1" textAlign="center">
						<Text as="span" mb="0.5rem">
							Vote{" "}
							<Box
								as="span"
								p="0.5rem 1.25rem"
								border="0.25rem solid"
								borderColor="white"
								color="yellow.400"
							>
								1
							</Box>{" "}
							Clare
						</Text>
						<Box as="br" />
						<Text
							as="span"
							display="block"
							mt="0.5rem"
							textTransform="uppercase"
							fontSize={["2.6rem", "5xl", "7xl", "8xl"]}
						>
							Glade-Wright
						</Text>
					</Heading>
					<Text textAlign="center" fontSize={["1rem", "1.5rem", "2rem"]}>
						For Councillor and Mayor
					</Text>
				</Box>
			</Box>
			<Box w="100%" bg="yellow.400" h="2rem" />
			<Box id="aboutclare" mx="auto" w="87.5vw" maxW="48rem" py="4rem">
				<VStack spacing="2rem" align="flex-start">
					{/* <Heading>A Bit About Me</Heading>
					<Text>
						I have 2 beautiful children who I tell most days how lucky we are to
						live in this part of the world. I grew up in Kingborough and I am
						determined to represent the communities views and values. I own and
						run a multi-award-winning eco-tourism business where I can proudly
						showcase our beautiful municipality to visitors. I am a natural born
						leader having worked in management and cross continental
						expeditions. I have a passion for gardening and work part time in
						the local garden centre.
					</Text> */}
					<AspectRatio w="95%" mx="auto" ratio={16 / 9}>
						<iframe
							src="https://player.vimeo.com/video/557384959"
							allow="fullscreen; picture-in-picture"
							allowfullscreen
						></iframe>
					</AspectRatio>
					{/* <Heading as="h3" size="lg">
						My Community Work
					</Heading>
					<Text>
						For the past 2 years I have led the community group{" "}
						<i>Sustainable Living in Kingborough</i> – we’ve run a wide range of
						events; film screenings, plant giveaways, and fundraisers. I am the
						fundraising coordinator for <i>Kingborough Community Missions</i>{" "}
						who work tirelessly supplying meals to the disadvantaged people in
						our region. In the last 2 years I petitioned the council to consider
						FOGO and for more Park and Ride facilities which have now approved.
						I have convened a network of independent candidates, called
						<i>Kingborough Thrives</i>, who will run in the coming general
						council election in October 2022.
					</Text>
					<Heading as="h3" size="lg">
						My Transferrable Skills for the Role of Mayor
					</Heading>
					<Text>
						My university degrees are Bachelor of Arts and Law. I am an esteemed
						businesswoman who has demonstrated my detail oriented and outcome
						focused energy which has resulted in an award-winning business
						model. I have a diverse background in management and leading tourism
						adventures internationally. This background has given me skills
						which enable me to manage and facilitate teams who have differing
						views, a skill set that will be well utilised as Mayor. As Mayor of
						Kingborough, I will work with my fellow councillors, to create
						cohesion, collaboration and a respectful council that represents the
						values of the community.
					</Text>
					<Heading as="h3" size="lg">
						Can you elect a Mayor without Prior Experience?
					</Heading>
					<Text>
						It’s important to recognise that the Mayor does not hold any more
						power than any other councillor; the Mayor contributes just 1 vote
						on each decision bought to a council. Deputy Mayor Jo Westwood is a
						shining example of being voted straight in and doing a wonderful job
						in both the capacity of Deputy and also Acting Mayor when required.
						I have been a dedicated attendee of council meetings over the last 2
						years and I have the required skills for the role.
					</Text> */}
					{/* <List spacing="1.5rem">
						<ListItem>
							<ListIcon
								color="blue.500"
								position="relative"
								bottom="0.1rem"
								as={Feather.FiChevronRight}
							/>
							Represent the community
						</ListItem>
						<ListItem>
							<ListIcon
								color="blue.500"
								position="relative"
								bottom="0.1rem"
								as={Feather.FiChevronRight}
							/>
							Act in the best interests of the community
						</ListItem>
						<ListItem>
							<ListIcon
								color="blue.500"
								position="relative"
								bottom="0.1rem"
								as={Feather.FiChevronRight}
							/>
							To facilitate communication by the council within the community
						</ListItem>
						<ListItem>
							<ListIcon
								color="blue.500"
								position="relative"
								bottom="0.1rem"
								as={Feather.FiChevronRight}
							/>
							To participate in the activities of the council
						</ListItem>
						<ListItem>
							<ListIcon
								color="blue.500"
								position="relative"
								bottom="0.1rem"
								as={Feather.FiChevronRight}
							/>
							To undertake duties and responsibilities as authorised by the
							council
						</ListItem>
					</List>
					<Text>
						Clare Glade-Wright grew up in Kingborough and went on to establish
						an award-winning accommodation business whereby she can contribute
						to the local economy and proudly showcase the beautiful
						municipality. Clare works closely with the community groups
						Sustainable Living in Kingborough which has a focus on resilient
						communities, and Kingborough Community Missions which aims to assist
						disadvantaged people in the region.
					</Text>
					<Text>
						Clare is a mother of 2 young children who love to explore the parks
						and natural assets Kingborough has to offer. You can find Clare
						working at the local garden centre on Monday – Thursday.
					</Text>
					
					 */}
				</VStack>
			</Box>
			<Box bg="blue.600" color="white">
				<Box w="87.5vw" maxW="48rem" mx="auto" py="4rem">
					<Heading>Clare's vision for a thriving Kingborough</Heading>
				</Box>
			</Box>
		</>
	);
};

export default HomeSectionOne;
