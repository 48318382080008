import React from "react";
import Layout from "src/components/Layout";
import HeadSEO from "src/components/HeadSEO";
import {
	Box,
	Heading,
	Container,
	Text,
	VStack,
	UnorderedList,
	List,
	ListIcon,
	ListItem,
	Grid,
	Divider,
	AspectRatio,
} from "@chakra-ui/react";
import * as Icon from "react-icons/bi";
import * as Feather from "react-icons/fi";
import ContactForm from "src/components/ContactForm";

import HomeBannerOne from "src/components/banners/HomeBannerOne";
import HomeBannerTwo from "src/components/banners/HomeBannerTwo";
import HomeBannerThree from "src/components/banners/HomeBannerThree";
import HomeSectionOne from "src/components/home/HomeSectionOne";
import HomeSectionTwo from "src/components/home/HomeSectionTwo";
import HomeSectionThree from "src/components/home/HomeSectionThree";
import HomeSectionFour from "src/components/home/HomeSectionFour";

const Home = () => {
	return (
		<Layout>
			<HeadSEO
				title="Vote 1 Clare Glade-Wright for Mayor | Kingborough Thrives"
				slug="/"
			/>
			<HomeSectionOne />
			<HomeBannerOne />
			<HomeSectionTwo />
			<HomeBannerTwo></HomeBannerTwo>
			<HomeSectionThree />
			<HomeBannerThree></HomeBannerThree>
			<HomeSectionFour />
			<Box as="section">
				<Box mx="auto" w="87.5vw" maxW="48rem" py="4rem" textAlign="center">
					<Box as="figure">
						<Box as="blockquote">
							<Text fontSize="2xl">
								“What sets me apart from other candidates, whom are mostly
								career politicians, is that I can bring a fresh face and new set
								of eyes to the decision making{" "}
								<Text as="span" color="blue.700">
									for our community
								</Text>
								”
							</Text>
						</Box>
						<Box as="figcaption" fontWeight="bold" mt="1.5rem">
							— Clare Glade-Wright
						</Box>
					</Box>
				</Box>
				{/* <ContactForm /> */}
			</Box>
		</Layout>
	);
};

export default Home;
