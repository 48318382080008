import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Box, Heading, Flex, Text } from "@chakra-ui/react";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";

const HomeBannerOne = () => {
	const { placeholderImage } = useStaticQuery(
		graphql`
			query {
				placeholderImage: file(relativePath: { eq: "thriving-economy.jpeg" }) {
					publicURL
					childImageSharp {
						gatsbyImageData(
							width: 2560
							placeholder: BLURRED
							formats: [AUTO, WEBP, AVIF]
						)
					}
				}
			}
		`
	);
	const image = getImage(placeholderImage);

	// Use like this:
	const bgImage = convertToBgImage(image);

	return (
		<Box as="section">
			<Box>
				<BackgroundImage
					className="sectionBanner"
					{...bgImage}
					preserveStackingContext
				>
					<div style={{ width: "100%" }}>
						<GatsbyImage image={image} alt={"KingsDesign team"} />
					</div>
					<Box
						position="absolute"
						w="100%"
						h="8rem"
						bottom="0"
						bgGradient="linear(to-t, #009DBF, rgba(0, 157, 191, 0))"
					/>
				</BackgroundImage>
			</Box>
			<Box pos="absolute" w="100%" top="0" zIndex="10000">
				<Flex
					w="100%"
					h="500px"
					align="flex-end"
					position="relative"
					justify="center"

					// top="5rem"
				>
					<Box
						mx="auto"
						w="87.5vw"
						maxW="max-content"
						boxShadow="xl"
						bg="white"
						p={["1.5rem", "2rem", "2.5rem"]}
						borderRadius="xl"
					>
						<Heading as="h2" fontSize={["xl", "2xl", "3xl", "4xl"]}>
							A thriving{" "}
							<Text as="span" color="blue.700">
								economy
							</Text>
						</Heading>
					</Box>
				</Flex>
			</Box>
		</Box>
	);
};
export default HomeBannerOne;
