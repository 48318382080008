import React from "react";
import { Box, VStack, Heading, Text } from "@chakra-ui/react";

const PolicyCard = ({ title, children }) => {
	return (
		<Box bg="white" p="2rem" borderRadius="xl">
			<VStack textAlign="center" spacing="1.5rem">
				<Heading as="h3" size="md">
					{title}
				</Heading>
				<Box w="4rem" h="0.25rem" bg="yellow.400" />
				<Text>{children}</Text>
			</VStack>
		</Box>
	);
};

export default PolicyCard;
