import React from "react";
import PolicyCard from "./PolicyCard";
import {
	Box,
	Heading,
	Container,
	Text,
	VStack,
	UnorderedList,
	List,
	ListIcon,
	ListItem,
	Grid,
	Divider,
	AspectRatio,
} from "@chakra-ui/react";

const HomeSectionThree = () => {
	return (
		<Box as="section" bg="blue.600">
			<Box mx="auto" w="87.5vw" maxW="80rem" py="4rem">
				<Grid gridTemplateColumns={["1fr", "1fr", "repeat(2, 1fr)"]} gap="2rem">
					{/* <Grid gridTemplateColumns={["1fr", "1fr", "1fr 1fr 1fr"]} gap="2rem"> */}
					<PolicyCard title="Pedestrian and Cycle Linkages">
						I acknowledge the recent petitions and concerns of those calling for
						better and safer pedestrian and cycle linkages in our community. I
						vision a link from Taroona all the way to Snug. If elected I will
						get behind these issues that I see as essential to a thriving
						community.
					</PolicyCard>
					<PolicyCard title="Urgent Care Center">
						Many parents I have spoken with want a 24-hour medical centre here
						in Kingborough. Urgent medical care centres can deal with
						non-life-threatening urgent conditions; this local solution will
						ease pressure on the Royal Hobart Hospital, and be more accessible
						to us as a community. If elected I will lobby the State Government
						for funding for these facilities.
					</PolicyCard>
					<PolicyCard title="Aboriginal Cultural Inclusion">
						As a long-time Kingborough resident, I want to know more about our
						local traditional culture. If elected I will engage with the South
						East Tasmanian Aboriginal Corporation and seek to raise respect for
						Muwinina culture in our community.
					</PolicyCard>
					<Box
						bg="white"
						p="2rem"
						borderRadius="xl"
						// gridColumn={[null, null, "span 3"]}
					>
						<VStack textAlign="center" spacing="1.5rem">
							<Heading as="h3" size="md">
								Community Collaboration
							</Heading>
							<Box w="4rem" h="0.25rem" bg="yellow.400" />
							<Text maxW="48rem">
								I believe the role of the council is to collaborate with the
								community, therefore, the Mayor needs to be accessible and
								approachable. I would introduce Mayor in the Chair, an excellent
								initiative by Hobart’s Lord Mayor, Anna Reynolds. This is a
								monthly opportunity for community members to sit and have a chat
								with the Mayor. I have a plan for more public events including
								cultural events, film screenings, and festive season
								celebrations such as the Christmas Carols.
							</Text>
						</VStack>
					</Box>
					<PolicyCard title="Social Inclusion and Ageing">
						There are many in our community who battle against immense barriers
						to employment: disability and culturally and linguistically diverse
						people. I’m inspired by the social enterprise in Hobart called
						Hamlet Café. It employs such individuals so as to provide training
						and boost their skills, experience, and confidence. I would like to
						see our council operate or support an enterprise of this nature in
						our own municipality. Additionally, if elected I will work with the
						organisations that support dementia-friendly communities to improve
						their standing in Kingborough.
					</PolicyCard>
					<PolicyCard title="Endorse PMAT">
						Planning is a complex issue and as such, I endorse the hard work by
						Planning Matters Alliance Tasmania to address planning challenges
						with the aim to better the lives of Tasmanians. It is fair to say we
						need to balance the need for more affordable housing with sensitive
						development.
					</PolicyCard>
					<PolicyCard title="Increased Services and Facilities">
						If elected I will lobby for increased police presence in our growing
						community. A 24-hour Police Station will serve us well and is long
						overdue. As for a Public Swimming Pool and Cinema, yes please
						Kingborough! If elected I will encourage operators to consider our
						region for these facilities.
					</PolicyCard>
					<PolicyCard title="My Position on Dogs">
						I understand the great joy and comfort dogs provide to many in our
						community. I also understand the responsibility of dog ownership. I
						will always support providing suitable, safe and sufficient exercise
						areas, and support the dedicated greyhound off-leash locations.
					</PolicyCard>
				</Grid>
			</Box>
		</Box>
	);
};

export default HomeSectionThree;
