import React from "react";
import PolicyCard from "./PolicyCard";
import {
	Box,
	Heading,
	Container,
	Text,
	VStack,
	UnorderedList,
	List,
	ListIcon,
	ListItem,
	Grid,
	Divider,
	AspectRatio,
} from "@chakra-ui/react";

const HomeSectionFour = () => {
	return (
		<Box as="section" bg="blue.600">
			<Box mx="auto" w="87.5vw" maxW="80rem" py="4rem">
				<Grid gridTemplateColumns={["1fr", "1fr", "repeat(2, 1fr)"]} gap="2rem">
					<PolicyCard title="Public Transport">
						With 8000+ commuters traveling to Hobart each day and very
						uninviting public transport, we end up with a congested bottleneck
						problem! While this is a State Government issue, I want to see our
						Council work more effectively with them to deliver a better public
						transport system. I have a viable business case for an Electric
						Shuttle Bus Service that is worthy of investigation. With major
						advances in e-bikes and e-scooters, we should be considering
						dedicated cycleways. If elected I will push for better decisions on
						how we use our State Government funding to address this issue.
					</PolicyCard>
					<PolicyCard title="Campaign Material">
						I have heard the community loud and clear when it comes to campaign
						“junk mail”. I have therefore made a conscious decision not to
						letterbox drop. Please help me by sharing my website and social
						media page. 🙏 I will turn my campaign corflute signs into tree
						guards when I have finished using them and will donate them to local
						Landcare groups. If you would like to support my campaign by
						displaying a yard sign please get in touch. 🙏
					</PolicyCard>
					<PolicyCard title="FOGO in Public Spaces">
						I am aware of the huge problem of compostable packaging ending up in
						either landfill or recycling bins, and neither of these is the right
						place for this waste. My vision is to provide FOGO bins in public
						spaces so that we can be environmentally sensible in our community.
					</PolicyCard>
					<PolicyCard title="Climate Change Policy">
						I hear many in the community want the Kingborough Climate Change
						Plan funded. Current estimates suggest it is under-resourced by 90%.
						I would like to explore the viability of employing a dedicated
						Climate Change Officer to facilitate and complete the actions in the
						Climate Change Plan. These include energy and greenhouse gas
						targets, climate change adaptation planning and community
						resilience, and coastal hazards management. If elected I will
						encourage our council to investigate the feasibility of
						crowd-funding this plan so those who want to contribute, may.
					</PolicyCard>
				</Grid>
			</Box>
		</Box>
	);
};

export default HomeSectionFour;
